import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import PostsView from '../views/PostsView.vue'
import LikersView from '../views/LikersView.vue'
import UserView from '../views/UserView.vue'
import UserSettings from '../views/UserSettings.vue'
import OrderView from '../views/OrderView.vue'
import DeepLink from '../views/DeepLink.vue'
import SinglePlace from '../views/SinglePlace.vue'
import ItemView from '../views/ItemView.vue'
import SettingsCardsList from '../views/UserSettings/CardsList.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'Home',
    component: Home,
    children: [
      {
        path: 'settings',
        component: UserSettings,
        children: [
          {
            path: 'cards-list',
            component: SettingsCardsList,
          },
        ],
      },
      {
        path: 'itemposts/:dishUid',
        component: PostsView,
        props: (route) => ({ dishUid: route.params.dishUid }),
      },
      {
        path: 'userposts/:userUid',
        component: PostsView,
        props: (route) => ({ userUid: route.params.userUid }),
      },
      {
        path: 'post/:postUid/likers',
        component: LikersView,
        props: (route) => ({ postUid: route.params.postUid }),
      },
      {
        path: 'user/:userUid',
        component: UserView,
        props: (route) => ({ userUid: route.params.userUid }),
      },
      {
        path: 'orders/:orderUid',
        component: OrderView,
        props: (route) => ({ orderUid: route.params.orderUid }),
      },
      {
        path: 'places/:placeUid',
        props: (route) => ({
          placeUid: route.params.placeUid,
          deepLink: true,
          menuModeQuery: Boolean(route.query.menumode),
          menuLangQuery: route.query.menulang,
        }),
        component: ItemView,
        // children: [
        //   {
        //     path: 'menu/:menuType',
        //     props: (route) => ({placeUid: route.params.placeUid, menuType: route.params.menuType})
        //   },
        //   {
        //     path: 'menu/:menuType/:categoryUid',
        //     props: ({params}) => ({placeUid: params.placeUid, menuType: params.menuType, categoryUid: params.categoryUid})
        //   },
        //   {
        //     path: 'menu/:menuType/drinks/:categoryUid',
        //     props: ({params}) => ({placeUid: params.placeUid, menuType: params.menuType, categoryUid: params.categoryUid})
        //   }
        // ]
      },
    ],
    beforeEnter: (_to, _from, next) => {
      /*
        Для временного переопределение глобальных стилей.
        ToDo: убрать после отказа от "position: fixed"
      */
      document.documentElement.classList.remove('__deep-link__')
      next()
    },
  },
  {
    path: '/place',
    name: 'DeepLink',
    component: DeepLink,
    alias: ['/app', '/user'],
    beforeEnter: (_to, _from, next) => {
      /*
        Для временного переопределение глобальных стилей.
        ToDo: убрать после отказа от "position: fixed"
      */
      document.documentElement.classList.add('__deep-link__')
      next()
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
