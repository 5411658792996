<template>
	<div class="o-view" v-if="item">
		<div class="o-view__header">
			<img 
				src="/img/backWhite.png" 
				srcset="/img/backWhite@3x.png 3x /img/backWhite@2x.png 2x /img/backWhite.png 1x"
				class="back-icon"
				@click="$router.replace('/orders')"
			/>
			<div class="o-view__header-title">
				{{ $t('delivery.order') }} {{ checkinName }}
			</div>
		</div>
		<div class="o-view__body">
			<div class="o-view__items">
				<div 
					class="o-view__items-item"
					v-for="(item, index) in orderItems"
					:key="item.uid"
				>
					<img :src="item.photo_uri || '/img/restaurantPlaceholder.jpg'" alt="" class="o-view__items-item__image">
					<div class="o-view__items-item__backdrop"></div>
					<template v-if="!isDelivery">
						<img 
							v-if="!item.invoice_uid && (item.status === 'new' || item.status === 'needs_user_approval') && isInRemove(item.uid)" 
							key="new-state-delete" 
							src="/img/orderDishCardIconAddDish.svg"
							class="o-view__items-item__icon"
							@click.stop="toRemove(item.uid)"
						/>
						<img 
							v-else-if="!item.invoice_uid && (item.status === 'new' || item.status === 'needs_user_approval')" 
							key="new-state-delete" 
							src="/img/orderDishCardIconDeleteDish.svg"
							class="o-view__items-item__icon"
							@click.stop="toRemove(item.uid)"
						/>
					</template>
					<img 
						v-if="item.denied || item.status === 'canceled'" 
						key="ready-state" 
						src="/img/iconDishIsCancelled.svg" 
						class="o-view__items-item__icon" 
					/>
					<img 
						v-else-if="item.status === 'active' || item.status === 'cooking'" 
						key="active-state" 
						src="/img/iconCooking.svg" 
						class="o-view__items-item__icon" 
					/>
					<img 
						v-else-if="item.status === 'ready'" 
						key="ready-state" 
						src="/img/cook-icon.svg" 
						class="o-view__items-item__icon -cook" 
						@click="orderItemIndexToReview = index"
					/>
				</div>
				<!-- <div 
					class="o-view__items-item"
					v-for="item in orderItems"
					:key="item.uid"
					:style="`background-image: url(${item.photo_uri || '/img/restaurantPlaceholder.jpg'});`"
					@click="openDish(item.menu_item_uid, item.comment)"
				>
					
					
				</div> -->
			</div>
			<div class="o-view__progresses">
				<div class="o-view__progresses-item" :class="{'-active': status === 'new'}">
					<div class="o-view__progresses-item__row">
						{{ $t('delivery.sent') }}{{ status === 'new' ? '' : (sentTime) ? ': '+sentTimeFormated : ''}}
					</div>
					<div class="o-view__progresses-item__row">
						<div 
							class="o-view__progresses-item__row-progress" 
							:style="{'width': `${sentPercent}%`}"
						></div>
						<div 
							class="o-view__progresses-item__row-timer" 
							:style="leftCounter" 
							v-if="status === 'new'"
						>{{ sentTimeFormated }}</div>
					</div>
				</div>
				<div class="o-view__progresses-item" :class="{'-active': status === 'active' || status === 'cooking'}">
					<div class="o-view__progresses-item__row">
						{{ $t('delivery.cooking') }}{{ status === 'active' || status === 'cooking' ? '' : (cookingTime) ? ': '+cookingTimeFormated : ''}}
					</div>
					<div class="o-view__progresses-item__row">
						<div 
							class="o-view__progresses-item__row-progress" 
							:style="{'width': `${cookingPercent}%`}"
						></div>
						<div 
							class="o-view__progresses-item__row-timer" 
							:style="cookingLeftCounter" 
							v-if="status === 'active' || status === 'cooking'"
						>{{ cookingTimeFormated }}</div>
					</div>
				</div>
				<div class="o-view__progresses-item" :class="{'-active': status === 'ready'}">
					<div class="o-view__progresses-item__row">
						{{ $t('delivery.ready') }}{{ status === 'ready' ? '' : (readyTime) ? ': '+readyTimeFormated : ''}}
					</div>
					<div class="o-view__progresses-item__row">
						<div 
							class="o-view__progresses-item__row-progress" 
							:style="{'width': `${readyPercent}%`}"
						></div>
						<div 
							class="o-view__progresses-item__row-timer" 
							:style="readyLeftCounter"
							v-if="status === 'ready'"
						>{{ readyTimeFormated }}</div>
					</div>
				</div>
				<div 
					v-if="isDelivery"
					class="o-view__progresses-item" 
					:class="{'-active': status === 'delivering'}"
				>
					<div class="o-view__progresses-item__row">
						{{ $t('delivery.delivering') }}{{ status === 'delivering' ? '' : (deliveryTime) ? ': '+deliveryTimeFormated : ''}}
					</div>
					<div class="o-view__progresses-item__row">
						<div 
							class="o-view__progresses-item__row-progress" 
							:style="{'width': `${deliveryPercent}%`}"
						></div>
						<div 
							class="o-view__progresses-item__row-timer" 
							:style="deliveryLeftCounter"
							v-if="status === 'delivering'"
						>{{ deliveryTimeFormated }}</div>
					</div>
				</div>
			</div>
			<!-- <div class="order-modal__body-header">
				<div class="paid-backdrop" v-if="paid"></div>
				<div class="paid-badge" v-if="paid">paid</div>
				<div class="body-header__title">
					{{ $t('delivery.order') }} {{checkinName}}
				</div>
				<div class="body-header__pretotal -flip" v-if="isDelivery && amounts.delivery">
					<div>{{ $t('order.delivery') }}:</div>
					<div>{{ currencySymbol }} {{ amounts.delivery }}</div>
				</div>
				<div class="body-header__total">
					<div>{{ $t('order.total') }}:</div>
					<div>{{ currencySymbol }} {{ amounts.total.toFixed(2) }}</div>
				</div>
			</div> -->
			<div class="o-view__btn" v-if="buttonText" @click="doThomething">
				{{ buttonText }}
			</div>
		</div>
		<SendReview
			v-if="orderItemToReview"
			:order-item="orderItemToReview"
			:user-uid="user_uid"
			@close="orderItemIndexToReview = -1"
		/>
	</div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import payMixin from '@/mixins/payMixin'
import callWaiterMixin from '@/mixins/callWaiterMixin'

import SendReview from '@/components/UI/SendReview'

export default {
	components: {
		SendReview
	},
	mixins: [ payMixin, callWaiterMixin],
	props: {
		orderUid: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			nowSeconds: new Date().getTime(),
			timer: null,
			orderItems: [],
			unsubscribe: null,
			invoices: window.ordersStore.invoices,
			categories: [],
			dishes: [],
			itemsToRemove: [],
			orderItemIndexToReview: -1
		}
	},
	computed: {
		...mapState('user', {
			user_uid: state => state.user?.uid,
			user: state => state.user
		}),
		createdQuery() {
			const query = this.$route.query.created
			if (!query || isNaN(query)) return 0
			return Number(query)
		},
		currentCheckin() {
			const {orderUid, $checkins} = this
			return $checkins.find(ch => ch.uid === orderUid)
		},
		isDelivery() {
			const type = this.currentCheckin?.type
			return type === 'delivery' || type === 'take_away'
		},
		orderItemsUids() {
			return this.orderItems.map(oi => oi.uid)
		},
		currentInvoice() {
			const orderItemsUids = this.orderItemsUids
			return this.invoices.find(({item_uids}) => {
				let i = orderItemsUids.length - 1
				let out = true
				while(i >= 0) {
					const orderItemUid = orderItemsUids[i]
					if (!~item_uids.indexOf(orderItemUid)) {
						out = false
						break
					}
					i--
				}
				return out
			})
		},
		currencySymbol() {
			return this.currentCheckin?.currencySymbol
		},
		amounts() {
			const { currentCheckin, orderItems } = this
			// if (currentCheckin.)]
			let total = 0,
				delivery = 0
			if (currentCheckin) {
				if (~['take_away', 'delivery'].indexOf(currentCheckin.type)) {
					total = Number(currentCheckin.total)
					delivery = total - currentCheckin.sub_total
				} else {
					total = this.getAmount(orderItems)
				}
			}
			return {
				delivery,
				total
			}
		},
		checkinName() {
			const { currentCheckin, createdQuery } = this
			const seconds = createdQuery || currentCheckin?.created_at?.seconds || currentCheckin?.checkin_time?.seconds;
			const created = (seconds) ? new Date(seconds * 1000) : new Date()
			return `${('0'+(created.getMonth()+1)).substr(-2)}-${('0'+created.getDate()).substr(-2)}-${('0'+created.getHours()).substr(-2)}:${('0'+created.getMinutes()).substr(-2)}`
		},
		item() {
			const orderItems = this.orderItems
			const active = orderItems.find(item => !item.denied && item.status !=='canceled')
			return active || orderItems[0]
		},
		status() {
			const item = this.item
			return item && item.status
		},
		items() {
			return this.currentCheckin?.items || []
		},
		// sent
		sentTime() {
			const {nowSeconds, item, status} = this
			// paid time
			const opened = item.opened_at.seconds * 1000
			if (status !== 'new') {
				// send to cook time
				const approved = item.approved_at?.seconds * 1000
				if (!approved) return 0
				return moment(approved).diff(opened)
			}
			return moment(nowSeconds).diff(opened)
		},
		sentTimeFormated() {
			const {status, sentTime} = this
			// if (status !== 'new') return ''
			return moment(sentTime).format('mm:ss')
		},
		sentPercent() {
			const sentTime = this.sentTime
			const hours = Math.floor(sentTime / 1000 / 60 / 60)
			if (hours > 0) {
				return 100
			}
			const minutes = Math.ceil(sentTime / 1000 / 60),
				percents = minutes * 100 / 60

			return Number(percents.toFixed(2))
		},
		leftCounter() {
			const sentPercent = this.sentPercent
			if (sentPercent >= 85) {
				return `right: 8px;`
			}
			return `left: ${sentPercent}%`
		},
		// cooking
		cookingTime() {
			const {nowSeconds, item, status} = this
			// send to cook time
			const approved = item.approved_at?.seconds * 1000
			if (!approved) return 0
			if (status !== 'active' && status !== 'cooking') {
				const readyAt = item.ready_at?.seconds * 1000
				if (!readyAt) return 0
				return moment(readyAt).diff(approved)
			}
			return moment(nowSeconds).diff(approved)
		},
		cookingTimeFormated() {
			const { cookingTime, status} = this
			// if (status !== 'active') return ''
			return moment(cookingTime).format('mm:ss')
		},
		cookingPercent() {
			const cookingTime = this.cookingTime
			const hours = Math.floor(cookingTime / 1000 / 60 / 60)
			if (hours > 0) {
				return 100
			}
			const minutes = Math.ceil(cookingTime / 1000 / 60),
				percents = minutes * 100 / 60

			return Number(percents.toFixed(2))
		},
		cookingLeftCounter() {
			const cookingPercent = this.cookingPercent
			if (cookingPercent >= 85) {
				return `right: 8px;`
			}
			return `left: ${cookingPercent}%`
		},
		// ready
		readyTime() {
			const {nowSeconds, item, status} = this
			// set ready state
			const ready = item.ready_at?.seconds * 1000
			if (!ready) return 0
			if (status !== 'ready') {
				const deliveringAt = item.delivering_at?.seconds * 1000
				if (!deliveringAt) return 0
				return moment(deliveringAt).diff(ready)
			}
			return moment(nowSeconds).diff(ready)
		},
		readyTimeFormated() {
			const { readyTime, status} = this
			return moment(readyTime).format('mm:ss')
		},
		readyPercent() {
			const readyTime = this.readyTime
			const hours = Math.floor(readyTime / 1000 / 60 / 60)
			if (hours > 0) {
				return 100
			}
			const minutes = Math.ceil(readyTime / 1000 / 60),
				percents = minutes * 100 / 60

			return Number(percents.toFixed(2))
		},
		readyLeftCounter() {
			const percents = this.readyPercent
			if (percents >= 85) {
				return `right: 8px;`
			}
			return `left: ${percents}%`
		},
		// delivery
		deliveryTime() {
			const {nowSeconds, item, status} = this
			// set ready state
			const delivery = item.delivering_at?.seconds * 1000
			if (!delivery) return 0
			if (status !== 'delivering') {
				return 0
			}
			return moment(nowSeconds).diff(delivery)
		},
		deliveryTimeFormated() {
			const { deliveryTime, status} = this
			return moment(deliveryTime).format('mm:ss')
		},
		deliveryPercent() {
			const time = this.deliveryTime
			const hours = Math.floor(time / 1000 / 60 / 60)
			if (hours > 0) {
				return 100
			}
			const minutes = Math.ceil(time / 1000 / 60),
				percents = minutes * 100 / 60

			return Number(percents.toFixed(2))
		},
		deliveryLeftCounter() {
			const percents = this.deliveryPercent
			if (percents >= 85) {
				return `right: 8px;`
			}
			return `left: ${percents}%`
		},
		needPay() {
			const leftToPay = this.leftToPay
			return leftToPay && leftToPay !== '0'
		},
		activeState() {
			const currentCheckin = this.currentCheckin
			if (currentCheckin?.status === 'approved') return 'sent'
		},
		buttonText() {
			if (this.itemsToRemove.length) return this.$t('order.accept').toUpperCase()
			return false
		},
		paid() {
			const currentInvoice = this.currentInvoice
			if (!currentInvoice) return false
			return currentInvoice.left_to_pay == 0
		},
		orderItemToReview() {
			return this.orderItems[this.orderItemIndexToReview]
		}
	},
	watch: {
		currencySymbol: {
			immediate: true,
			handler(val) {
				if (val) {
					this.subscribeOrderItems()
				}
			}
		},
		'orderItems.length': {
			immediate: true,
			handler: 'getCategories'
		}
 	},
	created() {
		this.plusSecond()
	},
	beforeDestroy() {
		if (typeof this.unsubscribe === 'function') {
			this.unsubscribe()
		}
	},
	methods: {
		removeItems: async function() {
			this.$store.dispatch("places/showLoader", true);
			const {currentCheckin, itemsToRemove} = this
			try {
				const { data } = await this.$axios.post(this.$API_URL("/orders/delete"), {
					item_uids: itemsToRemove,
					items_uids: itemsToRemove,
					object_uid: currentCheckin.object_uid,
					place_uid: currentCheckin.place_uid,
					user_uid: currentCheckin.user_uid
				})
				if (data.status === 'ERROR') {
					this.$iosAlert({title: this.$t('error'), text: data.error_code });
				} else {
					this.$set(this, 'itemsToRemove', [])
				}
			} catch (e) {
				console.log('this.$iosAlert(this.$t("order.waiterCoomingSoon"));' ,e)
			}
			this.$store.dispatch('places/hideLoader')
		},
		toRemove(uid) {
			const index = this.itemsToRemove.indexOf(uid)
			if (~index) {
				this.itemsToRemove.splice(index, 1)
			} else {
				this.itemsToRemove.push(uid)
			}
		},
		isInRemove(uid) {
			return ~this.itemsToRemove.indexOf(uid)
		},
		getAmount(items = []) {
			return items.reduce((acc, item) => {
				if (item.status !== 'canceled') {
					let price = Number(item.price)
					const modifications = item.modifications
					if (modifications) {
						for(const key in modifications) {
							const modArray = modifications[key]
							for(const modificator of modArray) {
								const _price = Number(modificator.price)
								price+= _price
							}
						}
					}
					return acc+= price
				} else {
					return acc
				}
			}, 0)
		},
		doThomething() {
			if (this.itemsToRemove.length) return this.removeItems()
			if (this.needPay) return this.payOrder()
		},
		payOrder: async function(oldMail='') {
			this.$store.dispatch('places/showLoader', true)
			const {currentCheckin, user_uid, user } = this
			let paymentType = ''
			try {
				paymentType = await this.$root.paymentType.show(
					this.currencySymbol, 
					this.amounts.total, 
					this.$PS.getSupportedFlowsByUid(currentCheckin.place_uid)
				);
			} catch (e) {
				console.log(e);
			}
			if (!paymentType) {
				this.$store.dispatch('places/hideLoader')
				return
			} else if (paymentType === 'cash') {
				await this.callWaiter(true)
				return
			}
			const ref = this.$DB.db.collection('users_private').doc(user_uid);
			const doc = await ref.get();
			if (doc.exists) {
				oldMail = doc.data()?.mail || ''
			}
			if (!oldMail && user.emailVerified && user.email) {
				oldMail = user.email
			}
			if (!user) {
				this.$store.dispatch('places/hideLoader')
				return
			}
			let email = ''
			try {
				email = await this.$root.emailBill.show(oldMail);
			} catch (e) {
				this.$store.dispatch('places/hideLoader')
				return
			}
			if (email === null) {
				this.$store.dispatch('places/hideLoader')
				return
			}
			if (!email || !this.$utils.validateEmail(email)) return this.payOrder(email)
			try {
				await this.$axios.post(this.$API_URL('/users/set'), {user: {email, uid: user_uid}})
			} catch (e) {
				this.$store.dispatch('places/hideLoader')
				console.log('e', e)
				return
			}
			try {
				const resp = await this.$axios.post(this.$API_URL('/checkin/pay'), {
					"checkin_uid": currentCheckin.uid,
					"payment_type": ~['applePay', 'googlePay', 'card'].indexOf(paymentType) ? 'card' : paymentType
				})
				const confirmation_url = resp?.data?.payload?.confirmation_url
				if (confirmation_url) {
					this.$store.dispatch('places/hideLoader')
					if (paymentType === 'sbp' || ~confirmation_url.indexOf('checkout.stripe.com')) {
                        window.newPayWindow.location.href = confirmation_url
                    } else {
						window.newPayWindow.close()
                        this.paymentData.confirmation_url = confirmation_url
                        this.$nextTick(() => {
                            this.listenIframe()
                            this.$store.dispatch('places/hideLoader')
                        })
                    }
				} else if (resp?.data?.error_code === 'NOT_SUPPORTED_ERROR') {
                    window.newPayWindow.close()
                    window.newPayWindow = null
                }
			} catch (e) {
				console.log('e', e)
				return
			}
			console.log('hide')
			this.$store.dispatch('places/hideLoader')
		},
		subscribeOrderItems() {
			const {currentCheckin, createdQuery} = this
			const createdAt = createdQuery
			this.unsubscribe = this.$DB.db.collection('order_items')
				.where('checkin_uid', '==', currentCheckin.uid)
				.onSnapshot((snapshot) => {
					const [newItems, updates, removed] = [[], [], []]
					const orderItems = this.orderItems
					snapshot.docChanges().forEach((change) => {
						const item = change.doc.data()
						const _date = item.created_at || item.checkin_time
						if (!createdAt || createdAt === _date.seconds) {
							if (change.type === "added") {
								newItems.push(item)
							}
							if (change.type === "modified") {
								updates.push(item)
							}
							if (change.type === "removed") {
								removed.push(item)
							}
						}
					})
					if (updates.length) {
						updates.forEach((updatedItem) => {
							const uid = updatedItem.uid
							const index = orderItems.findIndex(ch => ch.uid === uid)
							if (~index) {
								orderItems.splice(index, 1, updatedItem)
							}
						})
					}
					if (removed.length) {
						removed.forEach((updatedItem) => {
							const uid = updatedItem.uid
							const index = orderItems.findIndex(ch => ch.uid === uid)
							if (~index) {
								orderItems.splice(index, 1)
							}
						})
					}
					if (newItems.length) {
						orderItems.push(...newItems)
						if (this.$route.query.pay) {
							this.payOrder()
						}
					}
				})
		},
		plusSecond() {
			clearTimeout(this.timer)
			this.nowSeconds = new Date().getTime()
			this.timer = setTimeout(() => {
				this.plusSecond()
			}, 1000)
		},
		getItemsByChunck: async function (collection, where) {
			const { prop = 'uid', condition = '==',  value } = where
			const chunk = (await this.$DB.db.collection(collection).where(prop, condition, value).get()).docs.map(doc => doc.data())
			return chunk
		},

		getCategories: async function(val) {
			if (!val) return 
			this.$store.dispatch('places/showLoader', true)
			const { orderItems } = this
			const itemsIds = orderItems.map(oi => oi.menu_item_uid)
			const items = []
			const categories = []
			const dishes = []
			const categoryIds = new Set()
			while(itemsIds.length) {
				const chunk = await this.getItemsByChunck('menu_items', {
					prop: 'uid',
					condition: 'in',
					value: itemsIds.splice(0, 10)
				})
				items.push(...chunk)
			}
			for(const item of items) {
				categoryIds.add(item.category_uid)
			}
			const categoryIdsArray = Array.from(categoryIds)
			while(categoryIdsArray.length) {
				const chunk = await this.getItemsByChunck('menu_categories', {
					prop: 'uid',
					condition: 'in',
					value: categoryIdsArray.splice(0, 10)
				})
				categories.push(...chunk)
				for(const category of chunk) {
					const categoryUid = category.uid
					const _dishes = await this.getItemsByChunck('menu_items', {
						prop: 'category_uid',
						condition: '==',
						value: categoryUid
					})
					dishes.push(..._dishes)
				}
			}
			this.categories.push(...categories)
			this.dishes.push(...dishes)
			this.$store.dispatch('places/hideLoader')
		},
		openDish(dishUid, orderComment = '') {
			const { categories, dishes } = this
			const { category_uid } = dishes.find(d => d.uid === dishUid) || {}
			if (category_uid) {
				const category = categories.find(c => c.uid === category_uid)
				if (category) {
					const catDishes = dishes.filter(d => d.category_uid === category_uid)
					catDishes.sort((a, b) => {
						const [aIn, bIn] = [a.sort_index || 100, b.sort_index || 100]
						return aIn - bIn
					})
					this.$bus.$emit('GlobalModal/dishesOpen', {
						category: {
							...category,
							dishes: catDishes
						},
						currencySymbol: this.currencySymbol,
						startAt: dishUid, 
						onClose: () => ({}),
						addingStyle: 'z-index: 1000',
						hideButtons: true,
						hideModifiers: true,
						hideComments: true,
						orderComment
						// comments: 
					})
				}
			}
		}
	}	
}
</script>

<style lang="scss">
	.o-view {
		position: fixed;
		top: 0;
		bottom: 0;
		width: 100%;
		z-index: 106;
		background: $black;

		&__iframe {
			position: fixed;
			top: 0;
			bottom: 0;
			width: 100%;
			background: $black;
			z-index: 107;

			iframe {
				width: 100%;
				height: calc(100% - 50px);
			}

			&-head {
				height: 50px;
				position: relative;
				
				img {
					@include pos-centered-v;
					left: 16px;
				}
			}
		}

		&__header {
			position: relative;
			height: 55px;
			.back-icon {
				@include pos-centered-v;
				left: 16px;
			}

			&-title {
				@include pos-centered();
				@include font(18, 22);
				color: $white;
			}
		}

		&__items {
			width: calc(100% - 24px);
			@include flex(row, flex-start, flex-start);
			margin: 35px 12px 0 12px;
			flex-wrap: wrap;
			
			&-item {
				width: calc(33.33% - 8px);
				aspect-ratio: 1/1;
				padding: 0 4px 4px 4px;
				position: relative;
				

				&__image {
					width: 100%;
					height: 100%;
					object-fit: cover;
					border-radius: 4px;
				}

				&__backdrop {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					z-index: 1;
					background: rgba(0, 0, 0, .3);
				}

				&__icon {
					position: absolute;
					width: 32px;
					top: 8px;
    				right: 12px;
					z-index: 2;

					&.-cook {
						width: 20px;
						top: 18px;
						right: 20px;
					}
				}
			}
		}

		&__progresses {
			margin-top: 24px;
			@include flex(column, flex-start, flex-start);
			color: $white;

			&-item {
				width: 100%;
				height: 53px;
				// background: rgb( 26, 26, 26);
				@include flex(column, flex-start, flex-start);

				&:not(:first-child) {
					margin-top: 7px;
				}

				&__row {
					margin: 0 16px;
					color: rgb(71, 71, 71);
					position: relative;
					border-radius: 4px;

					&:first-child {
						@include font(18, 26, 500);
					}

					&:last-child {
						height: 4px;
						width: calc(100% - 32px);
						@include font(12, 14, 500);
						background: rgb(71, 71, 71);
					}

					&-progress {
						height: 100%;
						background: rgb(51, 140, 80);
						border-radius: 4px;
					}

					&-timer {
						@include pos-centered-v();
						margin-left: 8px;
					}
					
				}

				&.-active {
					.o-view__progresses-item__row {
						color: $white;
						&:first-child {
							height: 29px;
							@include font(20, 26, 600);
						}

						&:last-child {
							height: 16px;
							width: calc(100% - 32px);
							background: rgb(71, 71, 71);
							@include font(12, 26, 400);
						}
					}
				}
			}
		}


		&__btn {
			position: fixed;
			bottom: 30px;
			left: 16px;
			right: 16px;
			height: 40px;
			color: $black;
			@include font(18, 22);
			@include flex(row, center, center);
			background-color: $white;
			border-radius: 5px;
		}

		&__body {
			max-height: calc(100% - 55px);
			overflow: scroll;

			.order-modal__body-header {
				position: relative;
				.paid-backdrop {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background: rgba(0, 0, 0, .5);
					z-index: 1;
				}

				.paid-badge {
					position: absolute;
					background: $white;
					color: $black;
					z-index: 2;
					top: 5px;
					right: 16px;
					padding: 1px 5px;
					border-radius: 5px;
					@include font(16, 20, bold);
				}
			}
		}
	}
</style>