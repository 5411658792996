<template>
  <div class="banner" :class="{ 'banner--open': open }">
    <div class="banner__container-wrapper">
      <div class="banner__container">
        <div class="banner__content">
          <img src="/img/placepLogo.svg" />
          <h3 class="banner__heading">Тиндер ушел -<br />место осталось</h3>
          <div class="banner__main-text">
            <span class="red-color">PLACE</span> - лучшая альтернатива для
            знакомства и общения!
            <ul>
              <li>Без фейков и отложенного результата.</li>
              <li>
                Знакомься здесь и сейчас. Прямо в ресторане, где ты смотришь это
                меню.
              </li>
            </ul>
          </div>
          <h4 class="banner__subheading">
            Лучшие парни и девушки {{ cityName }} уже в <strong>PLACE!</strong>
          </h4>
        </div>
        <div class="banner__button">
          <a :href="promoUrl" target="_blank" @click="open = false">
            <img :src="downloadLogo" />
          </a>
        </div>
        <div class="banner__button" @click="open = false">Отмена</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    promoUrl: {
      type: String,
      default: '',
    },
    cityName: {
      type: String,
      default: 'МОСКВЫ',
    },
  },
  data() {
    return {
      open: true,
    }
  },
  computed: {
    android() {
      const { ua = '' } = this.$route.query
      return ua.toLowerCase().trim() === 'android'
    },
    ios() {
      const { ua = '' } = this.$route.query
      return ua.toLowerCase().trim() === 'apple'
    },
    downloadLogo() {
      return this.ios ? '/img/placepApple.svg' : '/img/placepGoogle.svg'
    },
  },
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.banner {
  @include fixed-modal(2000);
  @include flex(row, center, center);
  background: $black;
  color: $white;
  display: none;

  &--open {
    display: flex;
  }

  &__container {
    @include flex(column, flex-end, center);
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0) 100%
    );

    &-wrapper {
      width: 311px;
      height: 585px;
      border-radius: 10px;
      padding: 2px;
      background: linear-gradient(
        135deg,
        rgba(23, 115, 134, 0.9) 0%,
        rgba(190, 34, 89, 0.9) 98.75%
      );
      backdrop-filter: blur(13.591408729553223px);
    }
  }

  &__button {
    @include font(20, 22, 400);
    @include flex(row, center, center);
    width: 100%;
    height: 52px;
    border-top: solid 1px rgba(255, 255, 255, 0.4);
  }

  &__content {
    @include flex(column, center, center);
    width: 100%;
    height: calc(100% - 104px);
    padding: 20px;
  }

  &__heading {
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 130% */
    text-transform: uppercase;
  }

  &__main {
    &-text {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: left;

      > ul {
        margin: 0;
        padding: 0 1px 0 25px;
      }
    }
  }

  &__subheading {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    text-transform: uppercase;
  }

  .red-color {
    color: #fa0e38;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 125% */
    text-transform: uppercase;
  }
}
</style>
