<template>
  <div class="paytype" :class="{ '-open': open }">
    <div class="paytype__body">
      <div class="paytype__body-header">
        <span
          >{{ $t('pay.payment') }}&nbsp;&nbsp;&nbsp;&nbsp;{{ currencySymbol }}
          {{ amount }}</span
        >
        <img
          src="/img/greyCloseRound.png"
          srcset="/img/greyCloseRound@3x.png 3x /img/greyCloseRound@2x.png 2x /img/greyCloseRound.png 1x"
          class="close-icon"
          @click="$emit('submit')"
        />
      </div>
      <div class="paytype__body-paytypes" v-if="isHotel">
        <div
          class="paytype__body-paytypes__type"
          :class="{ '-active': pt.id === payType }"
          v-for="pt in payTypes"
          :key="pt.id"
          @click="payType = pt.id"
        >
          {{ pt.name }}
        </div>
      </div>
      <div class="paytype__body-types" v-if="!isHotel || payType === 'cash'">
        <div
          class="paytype__body-types__item"
          v-for="t in types"
          :key="t.id"
          @click="typeId = t.id"
          :class="{ '-active': typeId === t.id }"
        >
          <img
            v-if="t.image"
            key="image"
            :src="`/img/${t.image}.png`"
            :srcset="`/img/${t.image}@3x.png 3x /img/${t.image}@2x.png 2x /img/${t.image}.png 1x`"
          />
          <img v-else-if="t.icon" key="image2" :src="`/img/${t.icon}.svg`" />
        </div>
      </div>
      <div class="paytype__body-cashinfo" v-else-if="isHotel">
        {{ $t('pay.roomBalanceDesc') }}
      </div>
      <template v-if="payType === 'cash' && typeId === 'card'">
        <PaymentWindowBanksCardsList
          :payment-sources="paymentSources"
          :open="open"
          @startAddingCard="isAddingCardMode = true"
        />
        <!-- <div class="paytype__body-cardinfo">
					<img 
						v-for="cimg in cardsImages"
						:key="cimg" 
						:src="`/img/${cimg}.png`" 
						:srcset="`/img/${cimg}@3x.png 3x /img/${cimg}@2x.png 2x /img/${cimg}.png 1x`"
					/>
				</div> -->
      </template>
      <div
        class="paytype__body-cashinfo"
        v-else-if="payType !== 'room_balance'"
      >
        {{ cashInfo }}
      </div>
      <div class="paytype__body-paybtn" @click="$emit('submit', typeId)">
        <template v-if="payType === 'room_balance'">
          {{ $t('order.accept') }}
        </template>
        <template v-else-if="!buttonImage">
          {{ $t('delivery.buttons.pay') }}
        </template>
        <img v-else :src="buttonImage" />
      </div>
    </div>
  </div>
</template>
<script>
import checkGooglePay from '@/classes/GooglePay.js'
import PaymentWindowBanksCardsList from '@/components/PaymentWindowBanksCardsList'
import AddNewCard from '@/views/UserSettings/AddNewCard'
export default {
  components: {
    AddNewCard,
    PaymentWindowBanksCardsList,
  },
  data() {
    return {
      open: false,
      currencySymbol: '',
      amount: 0,
      typeId: 'card',
      isHotel: true,
      cardsImages: [
        // 'logoMirLight',
        'logoVisaLight',
        'logoMasterCardLight',
        'logoJcbLight',
      ],
      supportedSpb: false,
      supportedCards: true,
      googlePayAllowed: false,
      isDisabledCash: false,
      payType: 'cash',
      isRoomBalanceSupported: false,
      paymentSources: window.ordersStore.paymentSources,
      isAddingCardMode: false,
    }
  },
  computed: {
    payTypes() {
      const types = [
        {
          id: 'cash',
          name: this.$t('pay.paymentTypes'),
        },
      ]
      if (this.isRoomBalanceSupported) {
        types.push({
          id: 'room_balance',
          name: this.$t('pay.roomBalance'),
        })
      }
      return types
    },
    spbSelected() {
      return this.typeId === 'sbp'
    },
    types() {
      const { spbSelected, typeId } = this
      const out = []
      /*
			// if support ApplePaySession
			if (window.ApplePaySession) {
				out.push({
					id: 'applePay',
					icon: 'Apple_Pay_Mark_RGB_041619',
					sortIndex: 0
				})
			// if support googlePay
			} else if (this.googlePayAllowed) {
				out.push({
					id: 'googlePay',
					icon: (typeId === 'googlePay') ? 'googlePayActive' : 'googlePayInactive'
				})
			} */
      // if cards support
      if (this.supportedCards) {
        out.push({
          id: 'card',
          image:
            typeId === 'card'
              ? 'iconCreditCardActive'
              : 'iconCreditCardInactive',
        })
      }
      // sbp
      if (this.supportedSpb) {
        out.push({
          id: 'sbp',
          icon: spbSelected ? 'iconSbpActive' : 'iconSbpActive',
        })
      }
      if (!this.isDisabledCash) {
        out.push({
          id: 'cash',
          image: typeId === 'cash' ? 'iconCashActive' : 'iconCashInactive',
        })
      }

      return out
    },
    cashInfo() {
      const { typeId } = this
      const hashMap = {
        cash: 'waiterBill',
        sbp: 'spbText',
        applePay: 'applePayText',
        googlePay: 'googlePayText',
      }
      const infoByType = hashMap[typeId]
      return infoByType ? this.$t(`pay.${infoByType}`) : ''
    },
    buttonImage() {
      const { typeId } = this
      const hashMap = {
        sbp: '/img/iconSpbButton.svg',
        applePay: '/img/Apple_Pay_button.svg',
        googlePay: '/img/googlePayButton.svg',
      }
      return hashMap[typeId]
    },
  },
  watch: {
    open(val) {
      if (!val) {
        this.currencySymbol = ''
        this.amount = 0
        this.typeId = 'card'
        this.supportedSpb = false
        this.payType = 'cash'
        this.isRoomBalanceSupported = false
      }
    },
    supportedCards: 'supportedCardsHandler',
    payType(val) {
      if (val === 'room_balance') {
        this.typeId = 'cash'
      } else {
        this.supportedCardsHandler(this.supportedCards)
      }
    },
  },
  methods: {
    show(
      currencySymbol,
      amount,
      supportedFlows,
      isDisabledCash = false,
      isHotel = true
    ) {
      this.currencySymbol = currencySymbol
      this.amount = amount
      this.supportedSpb = !!supportedFlows.sbp
      this.supportedCards = !!supportedFlows.card
      this.isDisabledCash = isDisabledCash || !supportedFlows.cash
      this.isRoomBalanceSupported = supportedFlows.room_balance
      this.isHotel = isHotel
      return new Promise((resolve, reject) => {
        checkGooglePay().then((resp) => {
          this.googlePayAllowed = resp
        })
        this.$on('submit', (type) => {
          this.$off('submit')
          this.open = false
          this.supportedSpb = false
          this.supportedCards = true
          if (!type) {
            reject()
          } else {
            resolve(this.payType !== 'cash' ? this.payType : type)
          }
        })
        this.open = true
      })
    },
    supportedCardsHandler(val) {
      const newValue = val ? 'card' : 'cash'
      const types = this.types
      this.typeId = types.some((type) => type.id === newValue)
        ? newValue
        : types?.[0]?.id ?? ''
    },
  },
}
</script>
<style lang="scss" scoped>
.paytype {
  @include fixed-modal(1060);
  background: rgba(0, 0, 0, 0.8);
  color: $white;
  @include flex(column, flex-start, center);
  display: none;

  &.-open {
    display: block;
  }

  &__body {
    width: 91.4%;
    // height: 33.9%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.96);
    border-radius: 10px 10px 0 0;
    padding: 4.3%;
    color: $black;
    @include flex(column, flex-start, flex-start);

    > div {
      width: 100%;
    }
    &-header {
      height: 30px;
      @include flex(row, space-between, center);
      @include font(20, 24, 600);
      text-transform: uppercase;
    }

    &-paytypes {
      margin-top: 38px;
      @include flex(row, center, center);

      &__type {
        width: 50%;
        height: 54px;
        @include flex(row, center, center);

        &.-active {
          background: rgba(216, 216, 216, 0.93);
          border-radius: 12px;
        }
      }
    }

    &-types {
      height: 90px;
      // border: 1px solid rgba(0, 0, 0, 0.8);
      background: rgba(216, 216, 216, 0.93);
      border-radius: 12px;
      margin-top: 10%;
      @include flex(row, center, center);

      &__item {
        width: 50%;
        height: calc(100% - 6px);
        @include flex(row, center, center);

        &.-active {
          background: #ffffff;
          border: 0.5px solid rgba(0, 0, 0, 0.04);
          box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12),
            0px 3px 1px rgba(0, 0, 0, 0.04);
          border-radius: 9px;
        }

        > img {
          width: 70px;
          height: 50px;
        }
      }
    }
    &-cardinfo,
    &-cashinfo {
      margin-top: 12px;
      height: 56px;
    }

    &-cardinfo {
      @include flex(row, space-between, center);
      > img {
        width: 80px;
      }
    }

    &-cashinfo {
      text-align: left;
      @include font(16, 20, normal);
    }

    &-paybtn {
      margin-top: 26px;
      margin-bottom: 20px;
      height: 48px;
      @include flex(row, center, center);
      text-transform: uppercase;
      background: $black;
      color: $white;
      @include font(20, 24, 600);
      border-radius: 5px;

      img {
        object-fit: contain;
      }
    }
  }
}
</style>
