<template>
  <div class="downloadapp-modal" :class="{ '-open': open }">
    <div class="downloadapp-modal__body">
      <div class="downloadapp-modal__body-row">
        <div class="image-box">
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="44.8"
              height="44.8"
              rx="9"
              fill="url(#paint0_radial_12802_208100)"
            />
            <rect
              x="0.25"
              y="0.25"
              width="44.3"
              height="44.3"
              rx="8.75"
              stroke="url(#paint1_linear_12802_208100)"
              stroke-opacity="0.8"
              stroke-width="0.5"
            />
            <g filter="url(#filter0_b_12802_208100)">
              <path
                d="M55.4591 32.6165L32.6227 55.4423C32.2619 55.7995 31.7746 55.9999 31.2668 55.9999C30.759 55.9999 30.2717 55.7995 29.9109 55.4423L7.07444 32.6165C6.81627 32.3414 6.64129 31.999 6.56972 31.6287C6.49816 31.2584 6.53294 30.8755 6.67004 30.5241C6.81563 30.1779 7.06048 29.8825 7.37374 29.6752C7.687 29.4679 8.05464 29.3579 8.43035 29.359H17.9455V10.3375C17.9455 9.32854 18.3465 8.3609 19.0603 7.64746C19.7741 6.93401 20.7422 6.5332 21.7516 6.5332H40.782C41.7914 6.5332 42.7595 6.93401 43.4733 7.64746C44.187 8.3609 44.588 9.32854 44.588 10.3375V29.359H54.1032C54.4789 29.3579 54.8466 29.4679 55.1598 29.6752C55.4731 29.8825 55.7179 30.1779 55.8635 30.5241C56.0006 30.8755 56.0354 31.2584 55.9638 31.6287C55.8923 31.999 55.7173 32.3414 55.4591 32.6165Z"
                fill="white"
                fill-opacity="0.5"
              />
              <path
                d="M44.838 29.359H54.1032L7.37374 29.6752C7.687 29.4679 8.05464 29.3579 8.43035 29.359H17.6955H17.9455V29.109V10.3375C17.9455 9.32854 18.3465 8.3609 19.0603 7.64746C19.7741 6.93401 20.7422 6.5332 21.7516 6.5332H40.782C41.7914 6.5332 42.7595 6.93401 43.4733 7.64746C44.187 8.3609 44.588 9.32854 44.588 10.3375V29.109V29.359H44.838ZM55.2795 32.4426L32.4468 55.2646C32.4467 55.2648 32.4466 55.2649 32.4465 55.265C32.1325 55.5756 31.7086 55.7499 31.2668 55.7499C30.825 55.7499 30.401 55.5756 30.0871 55.265C30.087 55.2649 30.0869 55.2648 30.0868 55.2646L7.25408 32.4426C7.02967 32.2024 6.87754 31.9039 6.81518 31.5813C6.75278 31.2584 6.7828 30.9245 6.90175 30.618L55.2795 32.4426ZM55.6306 30.615C55.631 30.616 55.6314 30.617 55.6318 30.618C55.6322 30.619 55.6327 30.62 55.6331 30.621L55.8635 30.5241L55.6306 30.615Z"
                stroke="url(#paint2_linear_12802_208100)"
                stroke-opacity="0.5"
                stroke-width="0.5"
              />
            </g>
            <defs>
              <filter
                id="filter0_b_12802_208100"
                x="-0.466553"
                y="-0.466797"
                width="63.4666"
                height="63.4668"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.5" />
                <feComposite
                  in2="SourceAlpha"
                  operator="in"
                  result="effect1_backgroundBlur_12802_208100"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_backgroundBlur_12802_208100"
                  result="shape"
                />
              </filter>
              <radialGradient
                id="paint0_radial_12802_208100"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(22.4 22.4) rotate(90) scale(27.0667 25.022)"
              >
                <stop stop-color="#CEB765" />
                <stop offset="1" stop-color="#FEDB52" />
              </radialGradient>
              <linearGradient
                id="paint1_linear_12802_208100"
                x1="22.4"
                y1="0"
                x2="22.4"
                y2="44.8"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFEEAD" />
                <stop offset="1" stop-color="#FFEEAD" stop-opacity="0" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_12802_208100"
                x1="31.2668"
                y1="2.3332"
                x2="31.2668"
                y2="70.9332"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div class="text-box" v-html="$t('downloadAppModal.text')" />
      </div>
      <a @click="close" target="_blank" :href="appUrl" class="downloadapp-modal__body-row -button -gold">{{
        $t('downloadAppModal.button0')
      }}</a>
      <div @click="close" class="downloadapp-modal__body-row -button">
        {{ $t('downloadAppModal.button1') }}
      </div>
    </div>
  </div>
</template>

<script>
import { currentApplicationUrl } from '@/utils' 

export default {
  data() {
    return {
      open: false,
      appUrl: '',
      unwatchroute: null
    }
  },
  watch: {
    '$route.name': {
      immediate: true,
      handler: 'init'
    }
  },
  mounted() {
    this.unwatchroute = this.$watch('$route.name', this.init, { immediate: true })
  },
  methods: {
    init(newRouteName) {
      if (sessionStorage.getItem('hideDownloadAppModal')) return
      if (newRouteName === 'DeepLink') return
      if (currentApplicationUrl) {
        this.appUrl = currentApplicationUrl
      }
      setTimeout(() => {
        this.open = true
        if (this.unwatchroute && typeof this.unwatchroute === 'function') {
          this.unwatchroute()
          this.unwatchroute = null
        }
      }, 5000)
    },
    close() {
      this.open = false
      sessionStorage.setItem('hideDownloadAppModal', 'true')
    },
  },
}
</script>

<style lang="scss" scoped>
.downloadapp-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.85);
  visibility: hidden;
  transition: all 0.2s ease;

  &.-open {
    visibility: visible;
    .downloadapp-modal__body {
      transform: translateY(0);
    }
  }

  &__body {
    position: absolute;
    width: 100%;
    height: 238px;
    left: 0;
    bottom: 0;
    background: #2b2b2f;
    border-radius: 10px 10px 0px 0px;
    padding: 24px 16px 36px 16px;
    box-sizing: border-box;
    color: $white;
    transform: translateY(238px);
    transition: all 0.2s ease;

    @include flex(column, space-between, flex-start);

    &-row {
      width: 100%;
      @include flex(row, flex-start, flex-start);

      .image-box {
        margin: 0 18px;
      }

      .text-box {
        @include font(15, 20, 400);
        text-align: left;
      }

      &.-button {
        @include flex(row, center, center);
        @include font(20, 22, 400);
        height: 48px;
        text-transform: uppercase;

        &.-gold {
          background: #b8a05e;
          border-radius: 5px;
          text-decoration: none;
          color: $white;

          &:visited {
            text-decoration: none;
            color: $white;
          }
        }
      }
    }
  }
}
</style>
